import { getCustomHeaderTextColor } from '@/utils/getCustomHeaderTextColor';
import { HeaderInfoIcon } from '@/assets/svgExports/HeaderInfoIcon';
import ShopComponent from '../../Headers/ShopComponent';
import CommonNavbarText from '../../Headers/CommonNavbarText/CommonNavbarText';
import { getRoute, PATH } from '@/utils/routes';
import router from 'next/router';
import { useSSRSelector } from '@/redux/ssrStore';
import { useMemo, useState } from 'react';
import { AccordionCloseIcon, AccordionOpenIcon } from '../MegaMenuDrawerDesktop/svgIcons';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { useGetCategoriesList } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/components/utils';

export const baseNavItemClass =
  ' tw-w-[100%] tw-border-b-[1px] tw-border-solid tw-border-[#111C36]/[0.12] tw-py-[16px] tw-text-[14px] tw-font-normal tw-leading-[18px] ';

export const useGetNavList = (navigationList) => {
  const subNavObj = {
    page_type: 'subNavList',
    subNavs: [],
    is_editable: false,
    page_id: '616d2bd6152384798123d775',
    page_name: 'More',
    slug_url: 'subNavList',
  };

  const list = useMemo(() => {
    if (navigationList?.length > 0) {
      const tempNavList = [...navigationList];

      if (navigationList?.length > 5) {
        const extractedNavList = tempNavList.splice(5, tempNavList.length);
        subNavObj.subNavs = extractedNavList;
        const newNavList = [...tempNavList, subNavObj];
        return newNavList;
      } else {
        return navigationList;
      }
    }
  }, [navigationList]);

  return list;
};

export const NavigationItem = ({
  page,
  dropdownNav = false,
  isLastListBox,
  showMegaMenuMobileOnDesktopDrawer = false,
  ...props
}) => {
  const headerBgColor = props?.config?.color_scheme?.bg_color || '#ffffff';
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const [openMore, setOpenMore] = useState(false);

  const menuItemClick = (nav) => {
    if (nav?.page_type === 'blank_page' && nav?.is_external_url) {
      window.open(nav.slug_url, '_blank');
      props.closeParentContainer?.();
      return;
    } else if (nav.slug_url === 'subNavList') {
      setOpenMore(!openMore);
    } else {
      if (nav.is_editable) {
        router.push(
          getRoute(PATH.additionalPage(nav.slug_url), storeData?.store_info?.domain)
        );
      } else {
        router.push(getRoute(nav.slug_url, storeData?.store_info?.domain));
      }
      props.closeParentContainer?.();
    }
  };

  const navSubList = (nav) => {
    return (
      <div className={'tw-mt-[22.5px] tw-font-dmSans'}>
        <div className={''}>
          {nav.map((n, index, arr) => (
            <p
              key={n.page_id}
              className={twMerge(
                'tw-m-0 tw-text-[13px] tw-font-semibold',
                index !== arr?.length - 1 && 'tw-pb-[15.5px]'
              )}
              onClick={() => menuItemClick(n)}
            >
              {n.page_name}
            </p>
          ))}
        </div>
      </div>
    );
  };

  switch (page.slug_url) {
    case '':
      return (
        <li
          key={page.page_id}
          className={` relative ` + baseNavItemClass}
          onClick={() => menuItemClick(page)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ flex: '1 1 auto' }}>
              <CommonNavbarText
                classes={props.customItemClasses}
                bgColor={headerBgColor}
                page={page}
              />
            </div>
          </div>
        </li>
      );
    case 'shop':
      return (
        <ShopComponent
          page={page}
          showIcon={false}
          baseClass={baseNavItemClass}
          fromMobileMenuDrawer
          customItemClasses={props.customItemClasses}
          showMegaMenuMobileOnDesktopDrawer={showMegaMenuMobileOnDesktopDrawer}
          closeParentContainer={props.closeParentContainer}
        />
      );
    case 'subNavList':
      return (
        <li
          key={page.page_id}
          className={` relative ` + baseNavItemClass}
          onClick={() => menuItemClick(page)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ flex: '1 1 auto' }}>
              <CommonNavbarText
                classes={props.customItemClasses}
                page={{ page_name: 'More' }}
                bgColor={headerBgColor}
              />
            </div>
            {page?.subNavs?.length > 0 &&
              (!openMore ? (
                <AccordionCloseIcon
                // secondaryColor={invertAngleRightIconColor(headerBgColor)}
                />
              ) : (
                <AccordionOpenIcon
                // secondaryColor={invertAngleRightIconColor(headerBgColor)}
                />
              ))}
          </div>
          {openMore && navSubList(page.subNavs)}
        </li>
      );
    case 'about-us':
      return (
        <li
          key={page.page_id}
          className={
            ` relative ` + (props.fromMobileMenuDrawer ? baseNavItemClass : 'menu-item')
          }
          onClick={() => menuItemClick(page)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ flex: '1 1 auto' }}>
              {!props.fromMobileMenuDrawer && (
                <div className="mr3 visible-mobile">
                  <HeaderInfoIcon
                    color={
                      storeData?.theme?.colors?.primary_color !== '#ffffff'
                        ? '#000000'
                        : '#ffffff'
                    }
                  />
                </div>
              )}
              <CommonNavbarText
                classes={props.customItemClasses}
                bgColor={headerBgColor}
                page={page}
              />
            </div>
          </div>
        </li>
      );
    default:
      return (
        <li
          key={page.page_id}
          className={`${baseNavItemClass} ${getCustomHeaderTextColor(headerBgColor)} ${isLastListBox ? '!tw-border-none' : ''} ${props.customItemClasses}`}
          onClick={() => menuItemClick(page)}
        >
          {page.page_name}
        </li>
      );
  }
};

export const NewNavigationItem = ({ data, isLastListBox, ...props }) => {
  const headerBgColor = props?.config?.color_scheme?.bg_color || '#ffffff';
  const { store, categories } = useSSRSelector((state) => ({
    store: state.storeReducer.store,
    categories: state.catalogReducer.categories,
  }));
  const categoriesList = useGetCategoriesList();
  const [isDropdownOpened, setIsOpenDropdown] = useState(false);
  const isTypeDropdown = data.type === 'dropdown';

  if (!data.showOnHeader) {
    return null;
  }

  // const menuItemClick = (nav) => {
  //     if (openInNewTab) {
  //       window.open(link, '_blank');
  //     } else {
  //       router.push(getRoute(link, store?.store_info?.domain));
  //     }
  //   props.closeParentContainer?.();
  // };
  const navItemClick = (link: string, openInNewTab = false) => {
    if (openInNewTab) {
      window.open(link, '_blank');
    } else {
      router.push(getRoute(link, store?.store_info?.domain));
    }
  };

  function handleCategoryClick(name: string, isShopComponent, redirectionLink, openInNewTab, currentOptionData) {
    if (isShopComponent) {
      const currentCategory = categories?.find((val) => val?.name === name);
      if(currentCategory?.id) {
        router.push(
          getRoute(
            `${isPLPRequiredSystem(store?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
            }?cid=${currentCategory.id}`,
            store?.store_info?.domain
          ),
          undefined,
          { shallow: true }
        );
      }
      else {
        currentOptionData?.onClick();
      }
    }
    else {
      navItemClick(redirectionLink, openInNewTab)
    }
    props?.closeParentContainer();
  }

  function getDropdownList(isShopComponent, option) {
    if (!isShopComponent) {
      return option;
    }
    // Extracting newly added categories
    const newCatList = Object.values(categoriesList)?.filter((item: any) => {
      const isOldCat = !!option.find(
        (cat) => (item.id && cat.id === item.id) || cat.name === item.name
      );
      return !isOldCat;
    });

    return option?.concat(newCatList);
  }

  const navSubList = (nav, isShopComponent) => {
    const updatedNav = getDropdownList(isShopComponent, nav)
    return (
      <div
        className={'tw-mt-[22.5px] tw-max-h-[200px] tw-overflow-y-auto tw-font-dmSans'}
      >
        {updatedNav.map(
          (n, i) =>
            n.showOnHeader && (
              <p
                onClick={() => handleCategoryClick(n.name, isShopComponent, n.redirectionLink, n.redirectNewPage, n)}
                key={n.page_id}
                className={
                  'tw-m-0 tw-text-[13px] tw-font-semibold ' +
                  (i < nav.length - 1 && 'tw-pb-[17.5px] ') +
                  (props.customItemClasses || '')
                }
              >
                {n.name}
              </p>
            )
        )}
      </div>
    );
  };

  if (props?.showMegaMenu) {
    return (
      <ShopComponent
        page={props.megaMenuData}
        showIcon={false}
        baseClass={baseNavItemClass}
        fromMobileMenuDrawer
        customItemClasses={props.customItemClasses}
        showMegaMenuMobileOnDesktopDrawer={props?.showMegaMenuMobileOnDesktopDrawer}
        closeParentContainer={props.closeParentContainer}
      />
    )
  }

  return (
    <li
      className={` relative ${baseNavItemClass}  ${isLastListBox ? '!tw-border-none' : ''} `}
    >
      {data.redirectionLink ? (
        <div
          onClick={() => navItemClick(data?.redirectionLink, data?.redirectNewPage)}
          className={classNames(
            'tw-select-none tw-font-dmSans ',
            props.customItemClasses
          )}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <CommonNavbarText page={{ page_name: data.name }} bgColor={headerBgColor} />
        </div>
      ) : (
        <div>
          <div
            className="flex items-center justify-between tw-cursor-pointer "
            onClick={() => setIsOpenDropdown(!isDropdownOpened)}
          >
            <div className="flex items-center" style={{ flex: '1 1 auto' }}>
              <CommonNavbarText
                classes={props.customItemClasses}
                page={{ page_name: data.name }}
                bgColor={headerBgColor}
              />
            </div>
            {data?.option?.length > 0 &&
              isTypeDropdown &&
              (isDropdownOpened ? (
                <AccordionCloseIcon
                // secondaryColor={invertAngleRightIconColor(headerBgColor)}
                />
              ) : (
                <AccordionOpenIcon
                // secondaryColor={invertAngleRightIconColor(headerBgColor)}
                />
              ))}
          </div>
          {isDropdownOpened && navSubList(data.option, data?.isShopComponent)}
        </div>
      )}
    </li>
  );
};
