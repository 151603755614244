// import { toggleRfqFormModal } from '@/redux/actions';
// import { useSSRSelector } from '@/redux/ssrStore';
// import { STORE_CUSTOMIZATION } from '@/utils/constants';
// import { deviceWidth } from '@/utils/deviceWidth';
// import { getStoreType } from '@/utils/getStoreType';
// import { getRoute, PATH } from '@/utils/routes';
// import { useRouter } from 'next/router';
// import { useState } from 'react';
// import { useDispatch } from 'react-redux';

import { useGetNavList } from '@/components/DesignSystem/Drawer/MenuDrawerMobile/utils';
import { useSSRSelector } from '@/redux/ssrStore';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { getRoute, PATH } from '@/utils/routes';
import { routeToCollectionPage } from '@/utils/routeToCollectionPage';
import router from 'next/router';
import { useMemo } from 'react';
import store from 'src/redux/store';

const generateCategoryMenuItem = (categories) => {
  const storeData = store.getState()?.storeReducer?.store;
  const onCategoriesLinkHandle = (category) => {
    router.push(
      getRoute(
        `${
          isPLPRequiredSystem(storeData?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
        }?cid=${category.id}`,
        storeData?.store_info?.domain
      ),
      undefined,
      { shallow: true }
    );
  };

  const categoriesMap = categories.reduce((coll, item) => {
    coll[item.id] = {
      name: removeEmoticons(item.name) || 'Available Items',
      showOnHeader: item.status ?? 1,
      type: 'text',
      onClick: () => onCategoriesLinkHandle(item),
      id: item.id,
    };
    return coll;
  }, {});
  return categoriesMap;
};

export function useGetCategoriesList() {
  const { productTags, categories } = useSSRSelector((state) => ({
    categories: state.catalogReducer.categories,
    productTags: state.catalogReducer.productTags,
  }));
  return useMemo(() => {
    const concatCategories = [...(productTags || []), ...(categories || [])];
    if (!concatCategories?.length) return {};
    return generateCategoryMenuItem(concatCategories);
  }, [productTags, categories]);
}

export const useOldNavLinksList = () => {
  const { navigationList, productTags, categories, collections } = useSSRSelector(
    (state) => ({
      navigationList: state.additionalPagesReducer.navigationList,
      categories: state.catalogReducer.categories,
      productTags: state.catalogReducer.productTags,
      collections: state.catalogReducer.collections,
    })
  );

  const oldNavItemsList = useGetNavList(navigationList);

  const categoriesList = useGetCategoriesList();

  const collectionCategoriesList = useMemo(() => {
    if (!collections?.length) return {};
    const collectionMap = collections?.reduce((coll, item) => {
      coll[item.id] = {
        name: removeEmoticons(item.name),
        showOnHeader: item.status === 'A',
        type: 'text',
        onClick: () => routeToCollectionPage(item),
        option: generateCategoryMenuItem(item.categories),
      };
      return coll;
    }, {});
    return collectionMap;
  }, [productTags, categories, collections]);

  const defaultNavItemsList = useMemo(() => {
    const shopDrowdownList = Object.values(categoriesList);
    const list = (function convertOldNavListToNew(oldNavItemsList) {
      return oldNavItemsList?.map((item) => {
        const isDropdownAvailable =
          item.subNavs?.length || (item.page_type === 'shop' && shopDrowdownList?.length);

        return {
          redirectionLink: !isDropdownAvailable ? item.slug_url : '',
          name: item.page_name,
          showOnHeader: item.status ?? 1,
          option: item.subNavs
            ? convertOldNavListToNew(item.subNavs)
            : item.page_type === 'shop'
              ? shopDrowdownList
              : null,
          type: isDropdownAvailable ? 'dropdown' : 'text',
          isDefaultNavList: true,
          id: item.page_id,
          isShopComponent: item.page_type === 'shop',
        };
      });
    })(oldNavItemsList);
    return list;
  }, [productTags, categories, collectionCategoriesList, oldNavItemsList]);

  return { defaultNavItemsList, categoriesList, collectionCategoriesList };
};
