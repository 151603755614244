import { deviceWidth } from '@/utils/deviceWidth';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import { getRoute, PATH } from '@/utils/routes';
import { SupermarketIcon } from '@/assets/svgExports/SupermarketIcon';
import router from 'next/router';
import React, { forwardRef, useRef, useState } from 'react';
import CommonNavbarText from '../CommonNavbarText/CommonNavbarText';
import MegaMenuMobileView from '@/components/MegaMenu/MegaMenuMobileView';
import { useSSRSelector } from '@/redux/ssrStore';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { IS_DESKTOP } from '@/utils/checkClientDeviceType';
import useIsShopDropDownEnable from '../HeaderUtils/useIsShopDropDownEnable';
import { AccordionCloseIcon, AccordionOpenIcon } from '@/components/MegaMenu/svgIcons';
import styles from './shopComponent.module.scss';
import classNames from 'classnames';
import { ClickAwayListener } from '@mui/material';

const isDesktop = deviceWidth > 786;
const IS_SERVER = typeof window === 'undefined';
const showHeaderIcon = !(isDesktop || IS_SERVER);

const ShopComponent = forwardRef(function ShopComponent(props: any, ref: any) {
  const {
    page,
    setOpenMegaMenu,
    showMegaMenuDesktop,
    baseClass,
    showMegaMenuMobileOnDesktopDrawer,
  } = props;
  const headerBgColor = props?.config?.color_scheme?.bg_color || '#ffffff';
  const shopCompRef = useRef(null);

  const { storeData, categories, productTags, collections } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    categories: state.catalogReducer.categories,
    collections: state.catalogReducer.collections,
    productTags: state.catalogReducer.productTags,
  }));
  const isShopDropDownEnable = useIsShopDropDownEnable();

  const [showMegaMenuMobile, setShowMegaMenuMobile] = useState(false);
  const [, setOpenCollections] = useState(false);
  const [openShop, setOpenShop] = useState(false);

  const toggleMegaMenuMobile = (bool) =>
    setShowMegaMenuMobile(bool ?? !showMegaMenuMobile);

  const onShopClick = (page) => {
    if (document.querySelector('#CollectionCategoryProductWidget')) {
      document.querySelector('#CollectionCategoryProductWidget').scrollIntoView();
    } else {
      // router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));
    }
  };

  const handleShopClick = (page, dropdownNav) => {
    if (showMegaMenu()) {
      IS_DESKTOP && !showMegaMenuMobileOnDesktopDrawer
        ? setOpenMegaMenu((prevState) => ({ ...prevState, desktop: !prevState.desktop }))
        : setShowMegaMenuMobile((v) => !v);
      return;
    }

    if (collections?.length) {
      if (dropdownNav) {
        setOpenCollections(true);
        // setOpen((state) => ({ ...state, openShop: !state.openShop }));
        setOpenShop((v) => !v);
      } else {
        onShopClick(page);
        // menuItemClick(page);
      }
    } else {
      onShopClick(page);
      setOpenShop((v) => !v);
      // !dropdownNav && menuItemClick(page);
      // openShop
      //   ? setOpen((state) => ({ ...state, openShop: false }))
      //   : setOpen((state) => ({ ...state, openShop: true, openMore: false }));
    }
  };

  const onCategoriesLinkHandle = (category) => {
    router.push(
      getRoute(
        `${
          isPLPRequiredSystem(storeData?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
        }?cid=${category.id}`,
        storeData?.store_info?.domain
      ),
      undefined,
      { shallow: true }
    );
    toggleMegaMenuMobile(false);
  };

  const { height } = shopCompRef.current?.getBoundingClientRect() || {};

  const renderDropdownNav = () => {
    return (
      <ClickAwayListener onClickAway={() => setOpenShop(false)}>
        <div
          style={{ top: !props.fromMobileMenuDrawer && `${height + 6}px` }}
          className={
            !props.fromMobileMenuDrawer
              ? classNames('tw-relative tw-p-[20px] ', styles['cfe-dropdown-nav'])
              : 'tw-max-h-[220px] tw-overflow-y-auto tw-p-0 [&>div>p]:tw-text-[13px] [&>div>p]:tw-font-semibold [&>div>p]:tw-text-newBlack [&>div]:!tw-border-l-0 [&>div]:!tw-pl-0'
          }
        >
          <div
            className={
              !props.fromMobileMenuDrawer
                ? classNames(styles['cfe-dropdown-nav__items'], 'tw-max-h-[222px]')
                : 'tw-mt-[22.5px]'
            }
          >
            {[...productTags, ...categories]?.map((category, idx, renderArray) => (
              <p
                className={
                  'tw-select-none tw-text-newBlack ' +
                  (!props.fromMobileMenuDrawer
                    ? 'tw-m-0 tw-text-[12px] tw-font-semibold tw-tracking-[0.8px] ' +
                      (idx !== renderArray.length - 1 ? 'tw-pb-[16px]' : '')
                    : 'tw-m-0  ' +
                      (idx !== renderArray.length - 1 ? 'tw-pb-[17.5px]' : ''))
                }
                key={category.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onCategoriesLinkHandle(category);
                  props.closeParentContainer?.();
                }}
              >
                {removeEmoticons(category.name) || 'Available Items'}
              </p>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <li key={page.page_id} className={'relative ' + (baseClass || 'menu-item')} ref={ref}>
      <div
        ref={shopCompRef}
        className={
          'flex items-center justify-between ' + (showMegaMenuMobile && 'tw-mb-[20px]')
        }
        onClick={() => handleShopClick(page, isShopDropDownEnable)}
      >
        <div
          className="flex items-center"
          // style={{ flex: '1 1 auto' }}
        >
          {props.showIcon && showHeaderIcon ? (
            <div className="mr3">
              <SupermarketIcon
                color={
                  storeData?.theme?.colors?.primary_color !== '#ffffff'
                    ? '#000000'
                    : '#ffffff'
                }
                width={16}
                height={16}
              />
            </div>
          ) : null}
          <CommonNavbarText
            classes={props.customItemClasses}
            bgColor={headerBgColor}
            page={page}
            class="nav-name"
          />
        </div>
        {isShopDropDownEnable ? (
          <div className="items-center tw-ml-[2px] tw-flex  ">
            {openShop || showMegaMenuMobile || showMegaMenuDesktop ? (
              <AccordionCloseIcon />
            ) : (
              <AccordionOpenIcon />
            )}
          </div>
        ) : null}
      </div>
      {showMegaMenuMobile && (
        <MegaMenuMobileView
          open={showMegaMenuMobile}
          onClose={() => toggleMegaMenuMobile(false)}
          collectionItemWrapperClass={'tw-mb-[20px] [&.collectionName]:!tw-font-medium'}
        />
      )}
      {!!isShopDropDownEnable && openShop && renderDropdownNav()}
    </li>
  );
});

export default ShopComponent;
