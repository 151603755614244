/* eslint no-unused-vars: 0 */ // --> OFF

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useSSRSelector } from '@/redux/ssrStore';
import {
  baseNavItemClass,
  useGetNavList,
  NavigationItem,
  NewNavigationItem,
} from './utils';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import useInstallAppOverlayActions from '@/components/InstallAppInstructionsOverlay/useInstallAppOverlayActions';
import LogoutModal from '@/components/LogoutModal';
import { executeLogout } from '@/utils/excuteLogout';
import { useDispatch } from 'react-redux';
import router from 'next/router';
import { toggleLogoutConfirmModal } from '@/redux/actions';
import { getRoute, PATH } from '@/utils/routes';
import LocalStorageHelper from '@/utils/LocalStorageHelper';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import classNames from 'classnames';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import dynamic from 'next/dynamic';
import { IS_WEBVIEW } from '@/utils/checkRenderEnv';

const InstallAppInstructionsOverlay = dynamic(
  () => import('@/components/InstallAppInstructionsOverlay'),
  {
    ssr: false,
  }
);

export function MenuDrawerMobile({
  isVisible,
  closeDrawer,
  renderList,
  fromNewHeader = false,
  customItemList = undefined,
  ...props
}) {
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const dispatch = useDispatch();
  const headerBgColor = props?.config?.color_scheme?.bg_color || '#ffffff';
  // const isLoginVisible = props?.config?.hidden_components?.login_view;

  const {
    storeData,
    navigationList,
    // collections,
    userLogin,
    showLogoutConfirmationModal,
  } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    collections: state.catalogReducer.collections,
    navigationList: state.additionalPagesReducer.navigationList,
    userLogin: state.userReducer.isLogin,
    showLogoutConfirmationModal: state.commonReducer.showLogoutConfirmationModal,
  }));

  const { installAppFeatureStatus } = useFeatureLocks();
  const showMyOrders = isMobile;
  const showInstallApp = installAppFeatureStatus?.isVisible && !IS_WEBVIEW;

  const { openIntallAppOverlay } = useInstallAppOverlayActions();

  const navItemsState = useGetNavList(navigationList);

  const toggleLogoutModal = () => dispatch(toggleLogoutConfirmModal());

  const toggleAuthModal = () => {
    closeDrawer();
    if (userLogin) {
      toggleLogoutModal();
    } else {
      router.push(getRoute(PATH.ORDERS, storeData?.store_info?.domain));
    }
  };

  const getShopComponent = (navItemsState) => {
    return navItemsState?.find((data) => data.slug_url === 'shop');
  };

  const renderMobileLoginLogout = () => {
    if (!userLogin) {
      return null;
    }

    return (
      <div
        className={`flex items-center ${baseNavItemClass} !tw-border-none`}
        onClick={toggleAuthModal}
      >
        <div className="flex items-center">
          {/* <div className="mr3">
            <UserIconDark
              color={
                storeData?.theme?.colors?.primary_color !== '#ffffff'
                  ? '#000000'
                  : '#ffffff'
              }
              width="14"
              height="14"
            />
          </div> */}
          <span className={userLogin ? 'red' : 'black'}>
            {userLogin ? 'Logout' : 'Login'}
          </span>
        </div>
      </div>
    );
  };

  const DrawerList = (
    <Box
      sx={{
        width: '336px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100vh',
      }}
    >
      <div className="tw-mb-[12px]">
        <div
          className="wb-sprite ic-cross-black tw-cursor-pointer"
          onClick={closeDrawer}
        />
      </div>
      <List
        disablePadding
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {!fromNewHeader
          ? navItemsState?.map((page) => (
              <ListItem key={page} disablePadding>
                <NavigationItem
                  page={page}
                  dropdownNav
                  fromMobileMenuDrawer
                  isLastListBox={!showInstallApp && !showMyOrders}
                  customItemClasses="!tw-text-[15px]"
                  showMegaMenuMobileOnDesktopDrawer={true}
                  closeParentContainer={closeDrawer}
                />
              </ListItem>
            ))
          : renderList?.map((page) => (
              <ListItem key={page} disablePadding>
                <NewNavigationItem
                  data={page}
                  showMegaMenu={page?.isShopComponent ? showMegaMenu() : false}
                  megaMenuData={
                    page?.isShopComponent ? getShopComponent(navItemsState) : ''
                  }
                  showMegaMenuMobileOnDesktopDrawer={
                    page?.isShopComponent ? showMegaMenu() : false
                  }
                  fromMobileMenuDrawer
                  isLastListBox={!showInstallApp && !showMyOrders}
                  customItemClasses="!tw-text-[15px]"
                  closeParentContainer={closeDrawer}
                />
              </ListItem>
            ))}
        {Array.isArray(customItemList) &&
          customItemList?.map((child, idx) => {
            const isLastItemInWholeList =
              idx === customItemList?.length - 1 &&
              !showMyOrders &&
              !installAppFeatureStatus?.isVisible;

            return (
              <ListItem
                key={idx}
                disablePadding
                className={classNames(
                  `${baseNavItemClass} tw-cursor-pointer !tw-text-[15px] pwa:tw-hidden`,
                  isLastItemInWholeList && '!tw-border-none'
                )}
              >
                {child}
              </ListItem>
            );
          })}
        {showMyOrders && !fromNewHeader && (
          <ListItem
            disablePadding
            className={classNames(
              `${baseNavItemClass} tw-cursor-pointer !tw-text-[15px] pwa:tw-hidden`,
              !showInstallApp && '!tw-border-none'
            )}
          >
            <LoginComponent disableDropdown closeParentContainer={closeDrawer}>
              <div>My Orders</div>
            </LoginComponent>
          </ListItem>
        )}
        {showInstallApp && (
          <ListItem
            disablePadding
            className={`${baseNavItemClass}  !tw-border-none !tw-text-[15px] pwa:tw-hidden`}
            onClick={() => {
              closeDrawer();
              openIntallAppOverlay();
            }}
          >
            <div>Install App</div>
          </ListItem>
        )}
      </List>
      <ListItem disablePadding>{renderMobileLoginLogout()}</ListItem>
    </Box>
  );

  return (
    <>
      <Drawer
        className="tw-z-[600] tw-select-none tw-font-dmSans"
        open={isVisible}
        onClose={closeDrawer}
      >
        {DrawerList}
      </Drawer>
      {showLogoutConfirmationModal && (
        <LogoutModal
          toggleLogoutModal={toggleLogoutModal}
          executeLogout={executeLogout}
          showLogoutConfirmation={showLogoutConfirmationModal}
        />
      )}
      <InstallAppInstructionsOverlay />
    </>
  );
}
