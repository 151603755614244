import { useSSRSelector } from '@/redux/ssrStore';
import { useEffect, useState } from 'react';

function useIsShopDropDownEnable() {
  const { collections, customStore, isCustomShopDropDownEnable } = useSSRSelector(
    (state) => {
      const storeTheme = state.storeReducer.store?.theme;

      return {
        collections: state.catalogReducer.collections,
        customStore: storeTheme?.custom_theme_flag,
        isCustomShopDropDownEnable:
          storeTheme?.components?.Header?.[0]?.config?.hidden_components
            ?.isShopDropDownEnable ?? true,
      };
    }
  );

  const [isShopDropDownEnable, setIsShopDropDownEnable] = useState(false);

  useEffect(() => {
    if (customStore) setIsShopDropDownEnable(!!isCustomShopDropDownEnable);
    else if (!collections?.length) setIsShopDropDownEnable(true);
    else setIsShopDropDownEnable(false);
  }, [collections, customStore, isCustomShopDropDownEnable]);

  return isShopDropDownEnable;
}

export default useIsShopDropDownEnable;
